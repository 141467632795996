jQuery(document).ready(function($) {
	// checkJS
	$( 'html' ).removeClass( 'no-js' ).addClass( 'has-js' );

	// fitVids.
	$( '.entry-content' ).fitVids();

	// Responsive wp_video_shortcode().
	$( '.wp-video-shortcode' ).parent( 'div' ).css( 'width', 'auto' );

	/**
	 * Odin Core shortcodes
	 */

	// Tabs.
	$( '.odin-tabs a' ).click(function(e) {
		e.preventDefault();
		$(this).tab( 'show' );
	});

	// Tooltip.
	$( '.odin-tooltip' ).tooltip();

	// Masks
	$( '.money-mask input' ).maskMoney({
		prefix           : 'R$ ',
		thousands        : '.',
		decimal          : ',',
		selectAllOnFocus : true
	});

	/**
	 * POPULATE MODELS BASED ON SELECTED BRAND
	 */
	$( document.body ).on( 'change', '.populate-brands select', function() {
		var selectedBrand = $(this).val(),
			$modelsSelect = $(this).closest( '.populate-brands' ).next( '.populate-models' ).find( 'select' );

		$modelsSelect.html( '<option value="" selected="selected" class="gf_placeholder">Carregando...</option>' );

		$.post( ajax_object.ajax_url, {
			'action': 'populate_models',
			'brand': selectedBrand,
		}, function(data) {
			var html = '<option value="" selected="selected" class="gf_placeholder">Selecione</option>';

			$.each( data.data, function( index, value ) {
				html += '<option value="' + value.id + '">' + value.fipe_name + '</option>';
			});

			$modelsSelect.html( html );
		});
	});

	/**
	 * POPULATE MODEL YEARS BASED ON SELECTED MODEL
	 */
	$( document.body ).on( 'change', '.populate-models select', function() {
		var selectedModel = $(this).val(),
			$brandsSelect = $(this).closest( '.populate-models' ).prev( '.populate-brands' ).find( 'select' );
			$yearsSelect = $(this).closest( '.populate-models' ).next( '.populate-model-years' ).find( 'select' );

		$yearsSelect.html( '<option value="" selected="selected" class="gf_placeholder">Carregando...</option>' );

		$.post( ajax_object.ajax_url, {
			'action': 'populate_model_years',
			'brand': $brandsSelect.val(),
			'model': selectedModel,
		}, function(data) {
			var html = '<option value="" selected="selected" class="gf_placeholder">Selecione</option>';

			$.each( data.data, function( index, value ) {
				html += '<option value="' + value.id + '">' + value.name + '</option>';
			});

			$yearsSelect.html( html );
		});
	});

	/**
	 * POPULATE CITIES BASED ON SELECTED STATE
	 */
	$( document.body ).on( 'change', '.populate-states select', function() {
		var selectedState = $(this).val(),
			$citiesSelect = $(this).closest( '.populate-states' ).next( '.populate-cities' ).find( 'select' );

		$citiesSelect.html( '<option value="" selected="selected" class="gf_placeholder">Carregando...</option>' );

		$.post( ajax_object.ajax_url, {
			'action': 'populate_cities',
			'state': selectedState,
		}, function(data) {
			var html = '<option value="" selected="selected" class="gf_placeholder">Selecione</option>';

			$.each( data.data, function( index, value ) {
				html += '<option>' + value + '</option>';
			});

			$citiesSelect.html( html );
		});
	});

	/**
	 * OPEN COLLAPSED SECTION
	 */
	$( document.body ).ready(function() {
		var hash = location.hash,
			$target = $(hash);

		if ( $target.length ) {
			$( 'html, body' ).animate({
				scrollTop: $(hash).offset().top
			}, 1000 );

			if ( $target.hasClass( 'panel-collapse' ) ) {
				$target.addClass( 'in' );
			}
		}
	});

	/**
	 * ACTIVATE FLEET GALLERIES
	 */
	var galleryTop = [],
		galleryThumbs = [],
		$fleetGalleries = $( '#fleet .galleries' );

	$fleetGalleries.find( '.gallery' ).each(function( index ) {
		galleryTop[index] = new Swiper( $( this ).find( '.gallery-top' ).get( 0 ), {
			nextButton: '.swiper-button-next',
			prevButton: '.swiper-button-prev',
			spaceBetween: 22,
			preloadImages: false,
			lazyLoading: true,
			lazyLoadingInPrevNext: true,
			lazyLoadingInPrevNextAmount: 4
		});
		galleryThumbs[index] = new Swiper( $( this ).find( '.gallery-thumbs' ).get( 0 ), {
			spaceBetween: 22,
			slidesPerView: 'auto',
			centeredSlides: true,
			slideToClickedSlide: true,
			preloadImages: false,
			lazyLoading: true,
			lazyLoadingInPrevNext: true,
			lazyLoadingInPrevNextAmount: 4
		});
		galleryTop[index].params.control = galleryThumbs[index];
		galleryThumbs[index].params.control = galleryTop[index];
	}).promise().done( function(){
		$(this).closest( '.galleries' ).addClass( 'initialized' );
	});

	/**
	 * HANDLE FLEET GALLERIES VISIBILITY
	 */
	$( '#fleet .galleries-overview a' ).click( function(e) {
		e.preventDefault();

		var targetID = $( this ).attr( 'href' ),
			$fleetGalleries = $( '#fleet .galleries' ),
			$targetGallery = $fleetGalleries.find( targetID ),
			galleryHeight = $targetGallery.height() + 40;

		galleryTop[$targetGallery.index()].slideTo(0, 0);
		$( '#wrapper' ).css( 'height', galleryHeight );

		$fleetGalleries.addClass( 'open' );
		$targetGallery.addClass( 'active' );
		$( document.body ).addClass( 'gallery-open' );

		$( 'html, body' ).scrollTop(0);
	});

	$fleetGalleries.find( '.close' ).click( function(e) {
		e.preventDefault();

		$fleetGalleries
			.removeClass( 'open' )
			.find( '.active' )
			.removeClass( 'active' );

		$( '#wrapper' ).css( 'height', 'auto' );
		$( document.body ).removeClass( 'gallery-open' );
	});

	$( window ).on( 'resize', function() {
		if ( $fleetGalleries.hasClass( 'initialized' ) ) {
			$fleetGalleries.removeClass( 'initialized' );

			$.when(
				$.each( galleryTop, function( index, value ) {
					value.onResize();
				})
			).then(function() {
				$.when(
					$.each( galleryThumbs, function( index, value ) {
						value.onResize();
					})
				).then(function() {
					$fleetGalleries.addClass( 'initialized' );
				})
			});

			if ( $fleetGalleries.hasClass( 'open' ) ) {
				galleryHeight = $( '.active.gallery' ).innerHeight();
				$( '#wrapper' ).css( 'height', galleryHeight );
			}
		}
	});

	/**
	 * ACTIVATE OFFICE GALLERY
	 */
	var	$officeGallery = $( '#office-gallery' )

	$officeGallery.find( '.gallery' ).each(function() {
		new Swiper( $( this ).find( '.gallery-thumbs' ).get( 0 ), {
			nextButton: '.swiper-button-next',
			prevButton: '.swiper-button-prev',
			spaceBetween: 10,
			slidesPerView: 4,
			slidesPerGroup: 4,
			slideToClickedSlide: false,
			preloadImages: false,
			lazyLoading: true,
			lazyLoadingInPrevNext: true,
			lazyLoadingInPrevNextAmount: 4,
			breakpoints: {
				991: {
					slidesPerView: 2,
					slidesPerGroup: 2
				}
			}
		});
	}).promise().done( function(){
		$(this).addClass( 'initialized' );
	});

	/**
	 * LOAD MORE POSTS
	 */
	var	$newsList = $( '#news-list' );

	$newsList.on( 'click', '.load-posts', function(e) {
		e.preventDefault();

		var $button = $(this),
			nextPage = $button.attr( 'data-page' ),
			currentPostID = $button.attr( 'data-post-id' );

		$.post( ajax_object.ajax_url, {
			'action': 'load_more_posts',
			'page': nextPage,
			'postID': currentPostID,
		}, function(result) {
			if ( true === result.success ) {
				var data = result.data;

				$.each( data.posts, function(item, value) {
					$newsList.find( 'ul' ).append( '<li><a href="' + value.permalink + '" rel="bookmark">' + value.title + '</a></li>' );
				});

				if ( 0 < data.next_page ) {
					$button.attr( 'data-page', data.next_page );
				} else {
					$button.remove();
				}
			}
		});
	});

	/**
	 * HANDLE RECOVER PASSWORD LINK
	 */
	var	$systemsForm = $( '#systems-form' );

	$systemsForm.on( 'click', '.recover-password', function(e) {
		e.preventDefault();

		var $form = $systemsForm.find( 'form' ),
			$userID = $form.find( 'input[name="userid"]' ),
			userID = $userID.val(),
			targetURL = $(this).attr( 'href' ) + '?userid=' + userID;

		if ( userID ) {
			win = window.open(targetURL, '_blank');
			win.focus();
		} else {
			$userID.focus().prop( 'invalid', true ).attr( 'placeholder', 'Digite seu código de acesso' ).parent().addClass( 'has-error' );

			setTimeout( function() {
				$userID.focus().prop( 'invalid', true ).attr( 'placeholder', '' ).parent().removeClass( 'has-error' );
			}, 2000 );
		}
	});
});
