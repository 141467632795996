jQuery(document.body).ready( function($) {
	var $headerSiteInfo = $( '#header .site-info' ),
		$headerNav = $( '#header .navbar nav .nav' );
		$moduleFleet = $( 'section#fleet' );
		$moduleOfficesList = $( 'section#offices-list' );

	/**
	 * MAX-WIDTH: PHONES, TABLETS AND SMALL DESKTOPS
	 */
	enquire.register( 'screen and (max-width: 1199px)', {
		match : function() {
			// MODULE: ABOUT US SUMMARY
			//$moduleAboutUsSummary.find( 'p.image' ).detach().insertBefore( $moduleAboutUsSummary.find( '.col-lg-4 p:last-child' ) );
		},
		unmatch : function() {
			// MODULE: OUR STUDIO
			//$moduleAboutUsSummary.find( 'p.image' ).detach().appendTo( $moduleAboutUsSummary.find( '.col-lg-8' ) );
		}
	});

	/**
	 * MAX-WIDTH: PHONES AND TABLETS
	 */
	enquire.register( 'screen and (max-width: 991px)', {
		match : function() {
			$moduleFleet.find( '.right-side' ).detach().insertBefore( $moduleFleet.find( '.page-content' ) );
		},
		unmatch : function() {
			$moduleFleet.find( '.right-side' ).detach().insertAfter( $moduleFleet.find( '.page-content' ) );
		}
	});

	/**
	 * MAX-WIDTH: TABLETS
	 */
	enquire.register( 'screen and (min-width: 768px) and (max-width: 991px)', {
		match : function() {

		},
		unmatch : function() {

		}
	});

	/**
	 * MAX-WIDTH: PHONES
	 */
	enquire.register( 'screen and (max-width: 767px)', {
		match : function() {
			$headerSiteInfo.detach().insertAfter( $headerNav );
			$moduleOfficesList.parent().find( '.map' ).detach().insertBefore( $moduleOfficesList );
		},
		unmatch : function() {
			$headerSiteInfo.detach().prependTo( $( '#header .site-header .header-items-wrapper' ) );
		}
	});
});
